import React, { useState } from 'react'
import './ChannelSelect.css'
import { useClickOutside } from '../../../../hooks'

const ChannelSelect = ({ items, selectedItem, onSelect }) => {
   const [isActive, setIsActive] = useState(false)
   
   const handleToggleIsActive = () => setIsActive(isActive => !isActive)

   const handleCloseSelect = () => setIsActive(false)

   const handleSelect = (item) => {
      if (item?.id === selectedItem?.id) return
      onSelect(item)
   }
   
   const ref = useClickOutside(handleCloseSelect)
   
   return (
      <div ref={ref} className={`add-mailing-channel-select ${isActive ? 'active' : ''}`}>
         <button
            className='add-mailing-channel-select__btn'
            onClick={handleToggleIsActive}
         >
            <div className='add-mailing-channel-select__value'>
               {!selectedItem ? 'Все' : selectedItem.name}
               <img 
                  className='add-mailing-channel-select__dropdown-icon'
                  src='/assets/sidebar/select-dropdown-icon.svg' alt='' 
               />
            </div>
         </button>
         {items.length > 0 && (
            <div className='add-mailing-channel-select__list-wrapper'>
               <ul className='add-mailing-channel-select__list'>
                  <li
                     className={`add-mailing-channel-select__list-item ${selectedItem === null ? 'add-mailing-channel-select__list-item_selected' : ''}`}
                     onClick={() => handleSelect(null)}
                  >
                     <div className='add-mailing-channel-select__list-item-checkbox'>
                        <img src='/assets/sidebar/select-checkbox-icon.svg' alt='' />
                     </div>
                     <p className='add-mailing-channel-select__list-item-text'>
                        Все
                     </p>
                  </li>
                  {items.map(item => {
                     const isSelected = item.id === selectedItem?.id
                     return (
                        <li
                           key={item.id}
                           className={`add-mailing-channel-select__list-item ${isSelected ? 'add-mailing-channel-select__list-item_selected' : ''}`}
                           onClick={() => handleSelect(item)}
                        >
                           <div className='add-mailing-channel-select__list-item-checkbox'>
                              <img src='/assets/sidebar/select-checkbox-icon.svg' alt='' />
                           </div>
                           <p className='add-mailing-channel-select__list-item-text'>
                              {item.name}
                           </p>
                        </li>
                     )
                  })}
               </ul>
            </div>
         )}
      </div>
   )
}

export default ChannelSelect