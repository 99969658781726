import React from 'react'
import './TemplateList.css'
import TemplateListItem from './TemplateListItem'

const TemplateList = ({ templateSubsections, templates, handleTemplateSelect }) => {
   return (
      <div className="template-list-wrapper">
         <ul className="template-list__list">
            <TemplateListItem
               key={'Без группы'}
               templateSubsection={{ subsection_id: null, name: 'Без группы' }}
               templates={templates}
               onTemplateSelect={handleTemplateSelect}
            />
            {templateSubsections.map(templateSubsection => (
               <TemplateListItem
                  key={templateSubsection.subsection_id}
                  templateSubsection={templateSubsection}
                  templates={templates}
                  onTemplateSelect={handleTemplateSelect}
               />
            ))}
         </ul>
      </div>
   )
}

export default TemplateList