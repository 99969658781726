import '../../app.css'
import Loader from '../Loader'

const AuthLoader = () => {
   return (
      <div className="auth-loader">
         <Loader />
      </div>
   )
}

export default AuthLoader