import { useState } from 'react'
import './mailing.css'
import { managerStatuses } from '../../utils/consts'

const MailingHistoryItem = ({ item, tag, status, channelList }) => {
   const [isShowMoreChannels, setIsShowMoreChannels] = useState(false)
   const toggleShowMoreChannels = () => {
      setIsShowMoreChannels(!isShowMoreChannels)
   }
   const [isShowMoreTags, setIsShowMoreTags] = useState(false)
   const toggleShowMoreTags = () => {
      setIsShowMoreTags(!isShowMoreTags)
   }
   const [isShowMoreStatuses, setIsShowMoreStatuses] = useState(false)
   const toggleShowMoreStatuses = () => {
      setIsShowMoreStatuses(!isShowMoreStatuses)
   }
   const date = new Date(item.created_at * 1000).toLocaleDateString()
   const time = new Date(item.created_at * 1000)
      .toLocaleTimeString()
      .slice(0, -3)

   const isManagerInactive = item.manager?.status === managerStatuses.inactive

   return (
      <li className="mailing-mailing-history-item">
         <div className="mailing-history-item-block">
            <div className={`mailing-history-user-block ${isManagerInactive ? 'mailing-history-user-block_inactive' : ''}`}>
               <div className="mailing-history-user-wrapper">
                  <img
                     className="mailing-history-avatar"
                     src={
                        item.manager !== null
                           ? item.manager.photo !== null
                              ? item.manager.photo.url
                              : '/assets/settings/no_photo.svg'
                           : '/assets/settings/no_photo.svg'
                     }
                     alt=""
                  />
                  <div className="mailing-history-user-wrapper__user-info">
                     <span className="mailing-history-name">
                        {item.manager !== null
                           ? item.manager.username
                           : 'Администратор'}
                     </span>
                     {isManagerInactive && (
                        <span className='mailing-history-user-wrapper__status'>
                           Неактивный
                        </span>
                     )}
                  </div>
               </div>
            </div>
            {item.targets.channels?.length ? (
               <div className="mailing-history-channels-block">
                  <ul className="mailing-history-list">
                     {item.targets.channels.slice(0, 2).map((item) => {
                        return (
                           <li
                              key={item.id}
                              className="mailing-history-list_item"
                           >
                              <div className="mailing-history-channel-item">
                                 {item.type + '-' + item.username}
                              </div>
                           </li>
                        )
                     })}
                  </ul>
                  {item.targets.channels.length > 2 && (
                     <button
                        className="show-more-button"
                        onClick={toggleShowMoreChannels}
                     >
                        <span>{isShowMoreChannels ? 'Скрыть' : 'Еще'}</span>
                     </button>
                  )}
                  {isShowMoreChannels && (
                     <ul className="mailing-history-list-advance">
                        {item.targets.channels.slice(2).map((item) => {
                           return (
                              <li
                                 key={item.id}
                                 className="mailing-history-list_item"
                              >
                                 <div className="mailing-history-channel-item">
                                    {item.type + '-' + item.username}
                                 </div>
                              </li>
                           )
                        })}
                     </ul>
                  )}
               </div>
            ) : (
                <div className="mailing-history-empty-wrap">
                   <div className="mailing-history-empty">Не заданы статусы</div>
                </div>
            )}

            {item.targets.dialogSteps?.length ? (
               <div className="mailing-history-statuses-block">
                  <ul className="mailing-history-list">
                     {item.targets.dialogSteps.slice(0, 2).map((item) => {
                        return (
                           <li
                              key={item.id}
                              className="mailing-history-list_item"
                           >
                              <div
                                 style={{ backgroundColor: item.color }}
                                 className="mailing-history-statuses"
                              >
                                 {item.name}
                              </div>
                           </li>
                        )
                     })}
                  </ul>
                  {item.targets.dialogSteps.length > 2 && (
                     <button
                        className="show-more-button"
                        onClick={toggleShowMoreStatuses}
                     >
                        <span>{isShowMoreStatuses ? 'Скрыть' : 'Еще'}</span>
                     </button>
                  )}
                  {isShowMoreStatuses && (
                     <ul className="mailing-history-list-advance">
                        {item.targets.dialogSteps.slice(2).map((item) => {
                           return (
                              <li
                                 key={item.id}
                                 className="mailing-history-list_item"
                              >
                                 <div
                                    style={{ backgroundColor: item.color }}
                                    className="mailing-history-statuses"
                                 >
                                    {item.name}
                                 </div>
                              </li>
                           )
                        })}
                     </ul>
                  )}
               </div>
            ) : (
                <div className="mailing-history-empty-wrap">
                   <div className="mailing-history-empty">Не заданы статусы</div>
                </div>
            )}

            {item.targets.tags?.length ? (
                <div className="mailing-history-tags-block">
                   <ul className="mailing-history-list">
                      {item.targets.tags.slice(0, 2).map((item) => {
                         return (
                             <li key={item.id} className="mailing-history-list_item">
                                <div className="mailing-history-tags">
                                   {item.name}
                                </div>
                             </li>
                         )
                      })}
                   </ul>
                   {item.targets.tags.length > 2 && (
                       <button
                           className="show-more-button"
                           onClick={toggleShowMoreTags}
                       >
                          <span>{isShowMoreTags ? 'Скрыть' : 'Еще'}</span>
                       </button>
                   )}
                   {isShowMoreTags && (
                       <ul className="mailing-history-list-advance">
                          {item.targets.tags.slice(2).map((item) => {
                             return (
                                 <li
                                     key={item.id}
                                     className="mailing-history-list_item"
                                 >
                                    <div className="mailing-history-tags">
                                       {item.name}
                                    </div>
                                 </li>
                             )
                          })}
                       </ul>
                   )}
                </div>
            ) : (
                <div className="mailing-history-empty-wrap">
                   <div className="mailing-history-empty">Не заданы теги</div>
                </div>
            )}

            <div className="mailing-history-text-block">
               <span className="mailing-history-text" title={item.text}>
                  {item.text}
               </span>
            </div>
            <div className="mailing-history-file-block">
               {item.attachments.length > 0 ? (
                  <ul className='mailing-history-item-attachments-list'>
                     {item.attachments.map(attachment => (
                        <li key={attachment.id}>
                           <a
                              className="template-item_file-ref"
                              href={attachment.link}
                              download
                              target="_blank"
                              rel='noreferrer'
                           >
                              <span
                                 className="template-item_file-name"
                                 title={attachment.name}
                              >
                                 {attachment.name}
                              </span>
                           </a>
                        </li>
                     ))}
                  </ul>
               ) : (
                  <span style={{ color: 'black' }}>—</span>
               )}
            </div>
            <div className="mailing-history-time-block">
               <span>{date}</span>
               <span>{time}</span>
            </div>
         </div>
      </li>
   )
}

export default MailingHistoryItem
