import React from 'react'

import './AuthLogo.css'

const AuthLogo = () => {
  return (
    <div className='authLogo'>
      <svg viewBox="0 0 242 40" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M0 19.9596C0 25.2903 2.03214 30.3032 5.72256 34.074C8.85747 37.2772 13.0074 39.313 17.4079 39.8018C18.1096 39.8785 18.8135 39.9191 19.5152 39.9191C22.978 39.9191 26.4074 38.9802 29.3897 37.1982L36.9878 39.2789C37.3972 39.392 37.8337 39.2725 38.1344 38.9652C38.4352 38.6579 38.5521 38.2119 38.4415 37.7936L36.4052 30.03C38.5041 26.3638 39.4064 22.0381 38.9531 17.7871C38.4749 13.2907 36.4824 9.05039 33.3475 5.84723C29.6571 2.07641 24.7532 0 19.534 0C14.3169 0 9.41094 2.07641 5.72256 5.84723C2.03005 9.61591 0 14.6266 0 19.9596Z" fill="#00ACEF"/>
         <path d="M12.1821 15.7998H16.5366L14.0492 28.557H9.6946L12.1821 15.7998ZM13.1491 13.7533C12.6875 13.3285 12.4578 12.8036 12.4578 12.1762C12.4578 11.4379 12.7147 10.8318 13.2305 10.3516C13.7443 9.87356 14.4251 9.63456 15.2689 9.63456C16.0375 9.63456 16.6515 9.83517 17.111 10.2343C17.5726 10.6354 17.8023 11.1476 17.8023 11.775C17.8023 12.5433 17.5413 13.1728 17.0191 13.6572C16.497 14.1437 15.814 14.3871 14.9682 14.3871C14.2163 14.3871 13.6106 14.1758 13.1491 13.7533Z" fill="white"/>
         <path d="M23.4458 24.8614H29.4587L28.7214 28.5575H16.1673L16.7437 25.6382L23.7925 20.0363C24.607 19.394 25.1668 18.8477 25.4738 18.3995C25.7807 17.9515 25.9354 17.4926 25.9354 17.0231C25.9354 16.5536 25.7661 16.1802 25.4278 15.9049C25.0895 15.6296 24.607 15.493 23.9763 15.493C23.4082 15.493 22.8673 15.6338 22.3514 15.9177C21.8376 16.1994 21.3802 16.6091 20.9813 17.1426L17.9404 15.094C18.5858 14.0739 19.5027 13.263 20.6931 12.6569C21.8836 12.0529 23.2077 11.7499 24.6676 11.7499C25.8184 11.7499 26.8439 11.9463 27.7419 12.3389C28.6399 12.7316 29.3438 13.2758 29.8493 13.9757C30.3567 14.6736 30.6094 15.4546 30.6094 16.3189C30.6094 17.3539 30.3296 18.3206 29.7677 19.2147C29.2059 20.1089 28.2285 21.097 26.8313 22.181L23.4458 24.8614Z" fill="white"/>
         <path d="M52.6074 31.5V15.5H58.7324C59.8887 15.5 60.8496 15.6823 61.6152 16.0469C62.3861 16.4062 62.9616 16.8984 63.3418 17.5234C63.7272 18.1484 63.9199 18.8568 63.9199 19.6484C63.9199 20.2995 63.7949 20.8568 63.5449 21.3203C63.2949 21.7786 62.959 22.151 62.5371 22.4375C62.1152 22.724 61.6439 22.9297 61.123 23.0547V23.2109C61.6908 23.2422 62.235 23.4167 62.7559 23.7344C63.2819 24.0469 63.7116 24.4896 64.0449 25.0625C64.3783 25.6354 64.5449 26.3281 64.5449 27.1406C64.5449 27.9688 64.3444 28.7135 63.9434 29.375C63.5423 30.0312 62.9382 30.5495 62.1309 30.9297C61.3236 31.3099 60.3079 31.5 59.084 31.5H52.6074ZM55.5059 29.0781H58.623C59.6751 29.0781 60.4329 28.8776 60.8965 28.4766C61.3652 28.0703 61.5996 27.5495 61.5996 26.9141C61.5996 26.4401 61.4824 26.013 61.248 25.6328C61.0137 25.2474 60.6803 24.9453 60.248 24.7266C59.8158 24.5026 59.3001 24.3906 58.7012 24.3906H55.5059V29.0781ZM55.5059 22.3047H58.373C58.873 22.3047 59.3236 22.2135 59.7246 22.0312C60.1257 21.8438 60.4408 21.5807 60.6699 21.2422C60.9043 20.8984 61.0215 20.4922 61.0215 20.0234C61.0215 19.4036 60.8027 18.8932 60.3652 18.4922C59.9329 18.0911 59.2897 17.8906 58.4355 17.8906H55.5059V22.3047Z" fill="black"/>
         <path d="M72.2539 31.7344C71.0508 31.7344 70.0117 31.4844 69.1367 30.9844C68.2669 30.4792 67.5977 29.7656 67.1289 28.8438C66.6602 27.9167 66.4258 26.8255 66.4258 25.5703C66.4258 24.3359 66.6602 23.2526 67.1289 22.3203C67.6029 21.3828 68.2643 20.6536 69.1133 20.1328C69.9622 19.6068 70.9596 19.3438 72.1055 19.3438C72.8451 19.3438 73.543 19.4635 74.1992 19.7031C74.8607 19.9375 75.444 20.3021 75.9492 20.7969C76.4596 21.2917 76.8607 21.9219 77.1523 22.6875C77.444 23.4479 77.5898 24.3542 77.5898 25.4062V26.2734H67.7539V24.3672H74.8789C74.8737 23.8255 74.7565 23.3438 74.5273 22.9219C74.2982 22.4948 73.9779 22.1589 73.5664 21.9141C73.1602 21.6693 72.6862 21.5469 72.1445 21.5469C71.5664 21.5469 71.0586 21.6875 70.6211 21.9688C70.1836 22.2448 69.8424 22.6094 69.5977 23.0625C69.3581 23.5104 69.2357 24.0026 69.2305 24.5391V26.2031C69.2305 26.901 69.3581 27.5 69.6133 28C69.8685 28.4948 70.2253 28.875 70.6836 29.1406C71.1419 29.401 71.6784 29.5312 72.293 29.5312C72.7044 29.5312 73.0768 29.474 73.4102 29.3594C73.7435 29.2396 74.0326 29.0651 74.2773 28.8359C74.5221 28.6068 74.707 28.3229 74.832 27.9844L77.4727 28.2812C77.306 28.9792 76.9883 29.5885 76.5195 30.1094C76.056 30.625 75.4622 31.026 74.7383 31.3125C74.0143 31.5938 73.1862 31.7344 72.2539 31.7344Z" fill="black"/>
         <path d="M89.5996 15.0625L90.584 16.8906C90.235 17.1927 89.8444 17.4115 89.4121 17.5469C88.9798 17.6823 88.472 17.7708 87.8887 17.8125C87.3105 17.849 86.623 17.875 85.8262 17.8906C84.9303 17.9115 84.1986 18.0807 83.6309 18.3984C83.0631 18.7161 82.6283 19.1979 82.3262 19.8438C82.0241 20.4844 81.8262 21.3021 81.7324 22.2969H81.8652C82.2402 21.5625 82.7663 21.0026 83.4434 20.6172C84.1204 20.2318 84.9147 20.0391 85.8262 20.0391C86.821 20.0391 87.6986 20.263 88.459 20.7109C89.2246 21.1536 89.821 21.7969 90.248 22.6406C90.6803 23.4844 90.8965 24.5052 90.8965 25.7031C90.8965 26.9375 90.6647 28.0078 90.2012 28.9141C89.7376 29.8151 89.0788 30.5104 88.2246 31C87.3756 31.4896 86.3678 31.7344 85.2012 31.7344C84.0397 31.7344 83.0293 31.4818 82.1699 30.9766C81.3158 30.4661 80.6543 29.7135 80.1855 28.7188C79.722 27.7188 79.4902 26.487 79.4902 25.0234V23.9453C79.4902 21.1224 80.0163 19.0156 81.0684 17.625C82.1204 16.2344 83.6803 15.526 85.748 15.5C86.3626 15.4896 86.9121 15.487 87.3965 15.4922C87.8861 15.4974 88.3131 15.474 88.6777 15.4219C89.0423 15.3698 89.3496 15.25 89.5996 15.0625ZM85.2168 29.4688C85.7949 29.4688 86.2949 29.3203 86.7168 29.0234C87.1387 28.7214 87.4616 28.2943 87.6855 27.7422C87.9147 27.1901 88.0293 26.5365 88.0293 25.7812C88.0293 25.0365 87.9147 24.4036 87.6855 23.8828C87.4564 23.3568 87.1309 22.9557 86.709 22.6797C86.2871 22.4036 85.7845 22.2656 85.2012 22.2656C84.7637 22.2656 84.3704 22.3438 84.0215 22.5C83.6725 22.6562 83.3756 22.8854 83.1309 23.1875C82.8861 23.4896 82.6986 23.8568 82.5684 24.2891C82.4381 24.7214 82.3678 25.2188 82.3574 25.7812C82.3574 26.9115 82.6126 27.8099 83.123 28.4766C83.6335 29.138 84.3314 29.4688 85.2168 29.4688Z" fill="black"/>
         <path d="M100.576 23.6719V26H93.373V23.6719H100.576Z" fill="black"/>
         <path d="M110.457 28.2109L114.02 19.5H116.301L111.449 31.5H109.473L104.73 19.5H106.988L110.457 28.2109ZM106.277 19.5V31.5H103.551V19.5H106.277ZM114.754 31.5V19.5H117.457V31.5H114.754Z" fill="black"/>
         <path d="M125.621 31.7344C124.418 31.7344 123.379 31.4844 122.504 30.9844C121.634 30.4792 120.965 29.7656 120.496 28.8438C120.027 27.9167 119.793 26.8255 119.793 25.5703C119.793 24.3359 120.027 23.2526 120.496 22.3203C120.97 21.3828 121.632 20.6536 122.48 20.1328C123.329 19.6068 124.327 19.3438 125.473 19.3438C126.212 19.3438 126.91 19.4635 127.566 19.7031C128.228 19.9375 128.811 20.3021 129.316 20.7969C129.827 21.2917 130.228 21.9219 130.52 22.6875C130.811 23.4479 130.957 24.3542 130.957 25.4062V26.2734H121.121V24.3672H128.246C128.241 23.8255 128.124 23.3438 127.895 22.9219C127.665 22.4948 127.345 22.1589 126.934 21.9141C126.527 21.6693 126.053 21.5469 125.512 21.5469C124.934 21.5469 124.426 21.6875 123.988 21.9688C123.551 22.2448 123.21 22.6094 122.965 23.0625C122.725 23.5104 122.603 24.0026 122.598 24.5391V26.2031C122.598 26.901 122.725 27.5 122.98 28C123.236 28.4948 123.592 28.875 124.051 29.1406C124.509 29.401 125.046 29.5312 125.66 29.5312C126.072 29.5312 126.444 29.474 126.777 29.3594C127.111 29.2396 127.4 29.0651 127.645 28.8359C127.889 28.6068 128.074 28.3229 128.199 27.9844L130.84 28.2812C130.673 28.9792 130.355 29.5885 129.887 30.1094C129.423 30.625 128.829 31.026 128.105 31.3125C127.382 31.5938 126.553 31.7344 125.621 31.7344Z" fill="black"/>
         <path d="M138.584 31.7344C137.386 31.7344 136.357 31.4714 135.498 30.9453C134.644 30.4193 133.985 29.6927 133.521 28.7656C133.063 27.8333 132.834 26.7604 132.834 25.5469C132.834 24.3281 133.068 23.2526 133.537 22.3203C134.006 21.3828 134.667 20.6536 135.521 20.1328C136.381 19.6068 137.396 19.3438 138.568 19.3438C139.542 19.3438 140.404 19.5234 141.154 19.8828C141.91 20.237 142.511 20.7396 142.959 21.3906C143.407 22.0365 143.662 22.7917 143.725 23.6562H141.021C140.912 23.0781 140.652 22.5964 140.24 22.2109C139.834 21.8203 139.29 21.625 138.607 21.625C138.029 21.625 137.521 21.7812 137.084 22.0938C136.646 22.401 136.305 22.8438 136.061 23.4219C135.821 24 135.701 24.6927 135.701 25.5C135.701 26.3177 135.821 27.0208 136.061 27.6094C136.3 28.1927 136.636 28.6432 137.068 28.9609C137.506 29.2734 138.019 29.4297 138.607 29.4297C139.024 29.4297 139.396 29.3516 139.725 29.1953C140.058 29.0339 140.337 28.8021 140.561 28.5C140.785 28.1979 140.938 27.8307 141.021 27.3984H143.725C143.657 28.2474 143.407 29 142.975 29.6562C142.542 30.3073 141.954 30.8177 141.209 31.1875C140.464 31.5521 139.589 31.7344 138.584 31.7344Z" fill="black"/>
         <path d="M151.281 31.7344C150.083 31.7344 149.055 31.4714 148.195 30.9453C147.341 30.4193 146.682 29.6927 146.219 28.7656C145.76 27.8333 145.531 26.7604 145.531 25.5469C145.531 24.3281 145.766 23.2526 146.234 22.3203C146.703 21.3828 147.365 20.6536 148.219 20.1328C149.078 19.6068 150.094 19.3438 151.266 19.3438C152.24 19.3438 153.102 19.5234 153.852 19.8828C154.607 20.237 155.208 20.7396 155.656 21.3906C156.104 22.0365 156.359 22.7917 156.422 23.6562H153.719C153.609 23.0781 153.349 22.5964 152.938 22.2109C152.531 21.8203 151.987 21.625 151.305 21.625C150.727 21.625 150.219 21.7812 149.781 22.0938C149.344 22.401 149.003 22.8438 148.758 23.4219C148.518 24 148.398 24.6927 148.398 25.5C148.398 26.3177 148.518 27.0208 148.758 27.6094C148.997 28.1927 149.333 28.6432 149.766 28.9609C150.203 29.2734 150.716 29.4297 151.305 29.4297C151.721 29.4297 152.094 29.3516 152.422 29.1953C152.755 29.0339 153.034 28.8021 153.258 28.5C153.482 28.1979 153.635 27.8307 153.719 27.3984H156.422C156.354 28.2474 156.104 29 155.672 29.6562C155.24 30.3073 154.651 30.8177 153.906 31.1875C153.161 31.5521 152.286 31.7344 151.281 31.7344Z" fill="black"/>
         <path d="M164.057 31.7344C162.854 31.7344 161.814 31.4844 160.939 30.9844C160.07 30.4792 159.4 29.7656 158.932 28.8438C158.463 27.9167 158.229 26.8255 158.229 25.5703C158.229 24.3359 158.463 23.2526 158.932 22.3203C159.406 21.3828 160.067 20.6536 160.916 20.1328C161.765 19.6068 162.762 19.3438 163.908 19.3438C164.648 19.3438 165.346 19.4635 166.002 19.7031C166.663 19.9375 167.247 20.3021 167.752 20.7969C168.262 21.2917 168.663 21.9219 168.955 22.6875C169.247 23.4479 169.393 24.3542 169.393 25.4062V26.2734H159.557V24.3672H166.682C166.676 23.8255 166.559 23.3438 166.33 22.9219C166.101 22.4948 165.781 22.1589 165.369 21.9141C164.963 21.6693 164.489 21.5469 163.947 21.5469C163.369 21.5469 162.861 21.6875 162.424 21.9688C161.986 22.2448 161.645 22.6094 161.4 23.0625C161.161 23.5104 161.038 24.0026 161.033 24.5391V26.2031C161.033 26.901 161.161 27.5 161.416 28C161.671 28.4948 162.028 28.875 162.486 29.1406C162.945 29.401 163.481 29.5312 164.096 29.5312C164.507 29.5312 164.88 29.474 165.213 29.3594C165.546 29.2396 165.835 29.0651 166.08 28.8359C166.325 28.6068 166.51 28.3229 166.635 27.9844L169.275 28.2812C169.109 28.9792 168.791 29.5885 168.322 30.1094C167.859 30.625 167.265 31.026 166.541 31.3125C165.817 31.5938 164.989 31.7344 164.057 31.7344Z" fill="black"/>
         <path d="M180.027 24.3281V26.6484H173.637V24.3281H180.027ZM174.512 19.5V31.5H171.785V19.5H174.512ZM181.879 19.5V31.5H179.16V19.5H181.879Z" fill="black"/>
         <path d="M183.678 34.9844V29.1719H184.631C184.886 28.974 185.1 28.7057 185.271 28.3672C185.443 28.0234 185.584 27.6198 185.693 27.1562C185.808 26.6875 185.902 26.1641 185.975 25.5859C186.048 25.0026 186.113 24.375 186.17 23.7031L186.514 19.5H195.186V29.1719H197.045V34.9844H194.311V31.5H186.459V34.9844H183.678ZM187.537 29.1719H192.482V21.7812H188.967L188.779 23.7031C188.675 24.974 188.537 26.0625 188.365 26.9688C188.193 27.875 187.917 28.6094 187.537 29.1719Z" fill="black"/>
         <path d="M198.375 31.5L202.805 25.3047L198.414 19.5H201.68L205.094 24.3359H205.766V19.5H208.594V24.3359H209.25L212.664 19.5H215.93L211.562 25.3047L215.969 31.5H212.633L209.219 26.6562H208.594V31.5H205.766V26.6562H205.141L201.711 31.5H198.375Z" fill="black"/>
         <path d="M222.945 31.7344C221.742 31.7344 220.703 31.4844 219.828 30.9844C218.958 30.4792 218.289 29.7656 217.82 28.8438C217.352 27.9167 217.117 26.8255 217.117 25.5703C217.117 24.3359 217.352 23.2526 217.82 22.3203C218.294 21.3828 218.956 20.6536 219.805 20.1328C220.654 19.6068 221.651 19.3438 222.797 19.3438C223.536 19.3438 224.234 19.4635 224.891 19.7031C225.552 19.9375 226.135 20.3021 226.641 20.7969C227.151 21.2917 227.552 21.9219 227.844 22.6875C228.135 23.4479 228.281 24.3542 228.281 25.4062V26.2734H218.445V24.3672H225.57C225.565 23.8255 225.448 23.3438 225.219 22.9219C224.99 22.4948 224.669 22.1589 224.258 21.9141C223.852 21.6693 223.378 21.5469 222.836 21.5469C222.258 21.5469 221.75 21.6875 221.312 21.9688C220.875 22.2448 220.534 22.6094 220.289 23.0625C220.049 23.5104 219.927 24.0026 219.922 24.5391V26.2031C219.922 26.901 220.049 27.5 220.305 28C220.56 28.4948 220.917 28.875 221.375 29.1406C221.833 29.401 222.37 29.5312 222.984 29.5312C223.396 29.5312 223.768 29.474 224.102 29.3594C224.435 29.2396 224.724 29.0651 224.969 28.8359C225.214 28.6068 225.398 28.3229 225.523 27.9844L228.164 28.2812C227.997 28.9792 227.68 29.5885 227.211 30.1094C226.747 30.625 226.154 31.026 225.43 31.3125C224.706 31.5938 223.878 31.7344 222.945 31.7344Z" fill="black"/>
         <path d="M230.674 36V19.5H233.455V21.4844H233.619C233.765 21.1927 233.971 20.8828 234.236 20.5547C234.502 20.2214 234.861 19.9375 235.314 19.7031C235.768 19.4635 236.346 19.3438 237.049 19.3438C237.976 19.3438 238.812 19.5807 239.557 20.0547C240.307 20.5234 240.9 21.2188 241.338 22.1406C241.781 23.0573 242.002 24.1823 242.002 25.5156C242.002 26.8333 241.786 27.9531 241.354 28.875C240.921 29.7969 240.333 30.5 239.588 30.9844C238.843 31.4688 237.999 31.7109 237.057 31.7109C236.369 31.7109 235.799 31.5964 235.346 31.3672C234.893 31.138 234.528 30.862 234.252 30.5391C233.981 30.2109 233.77 29.901 233.619 29.6094H233.502V36H230.674ZM233.447 25.5C233.447 26.276 233.557 26.9557 233.775 27.5391C233.999 28.1224 234.32 28.5781 234.736 28.9062C235.158 29.2292 235.669 29.3906 236.268 29.3906C236.893 29.3906 237.416 29.224 237.838 28.8906C238.26 28.5521 238.577 28.0911 238.791 27.5078C239.01 26.9193 239.119 26.25 239.119 25.5C239.119 24.7552 239.012 24.0938 238.799 23.5156C238.585 22.9375 238.268 22.4844 237.846 22.1562C237.424 21.8281 236.898 21.6641 236.268 21.6641C235.663 21.6641 235.15 21.8229 234.729 22.1406C234.307 22.4583 233.986 22.9036 233.768 23.4766C233.554 24.0495 233.447 24.724 233.447 25.5Z" fill="black"/>
      </svg>
    </div>
  )
}

export default AuthLogo