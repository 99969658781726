import { useEffect, useState } from 'react'
import axios from 'axios'

import './mailing.css'

import { mailingTypesMap } from '../../utils/consts'
import AddMailing from '../modals/AddMailing'
import MailingHistoryItem from './MailingHistoryItem'
import MailingTypeSelect from './MailingTypeSelect'
import IncorrectNumbersModal from '../modals/IncorrectNumbers'
import MailingService from '../../services/mailingService'

const Mailing = ({
   tag,
   allowedTag,
   status,
   allowedStatus,
   channelList,
   allowedChannel,
   currentUser,
}) => {
   const [historyMailing, setHistoryMailing] = useState([])
   const [nextHistoryMailingCursor, setNextHistoryMailingCursor] = useState(null)
   const [mailingType, setMailingType] = useState({})
   const [isShowAddMailingModal, setIsShowAddMailingModal] = useState(false)
   const [showIncorrectNumbersModal, setShowIncorrectNumbersModal] = useState(false)
   const [incorrectNumbers, setIncorrectNumbers] = useState([])
   const [contactsListMailingCache, setContactsListMailingCache] = useState(null)
   const [shouldClearMailingModalCache, setShouldClearMailingModalCache] = useState(false)

   const toggleShowAddMailingModal = () => {
      setIsShowAddMailingModal(!isShowAddMailingModal)
   }
   
   const handleSelectMailingType = (type) => {
      setMailingType(type)
      toggleShowAddMailingModal()
   }

   const handleOpenIncorrectNumbersModal = (numbers, data) => {
      setIncorrectNumbers(numbers)
      setContactsListMailingCache(data)
      setShowIncorrectNumbersModal(true)
   }

   const handleCloseIncorrectNumbersModal = () => {
      setShowIncorrectNumbersModal(false)
      setIncorrectNumbers([])
      setShouldClearMailingModalCache(true)
   }

   const handleChangeFileForIncorrectNumbersModal = () => {
      handleSelectMailingType(mailingTypesMap.BY_CONTACTS_FILE)
      setShowIncorrectNumbersModal(false)
      setIncorrectNumbers([])
   }

   const handleSubmitIncorrectNumbersModal = async () => {
      if (!contactsListMailingCache) return
      try {
         const formData = contactsListMailingCache.formData
         formData.append('ignoreInvalid', 'true')
         const data = await MailingService.sendMailing(contactsListMailingCache.type, formData)
         
         if (!data.error) {
            handleCloseIncorrectNumbersModal()
            setHistoryMailing((prevState) => {               
               return [data.data, ...prevState]
            })
         } else {
            alert(data.data.error)
         }
      } catch (err) {
         alert(err)
      }
   }

   const loadHistoryMailing = () => {
      nextHistoryMailingCursor &&
         axios({
            method: 'get',
            url: `/api/v1/mass-send/get?limit=20&cursor=${nextHistoryMailingCursor}`,
            withCredentials: true,
         })
            .then((response) => {
               if (response.data.error) {
                  console.log(response.data.data.error)
               } else {
                  setNextHistoryMailingCursor(response.data.data.next_cursor)
                  setHistoryMailing([...historyMailing, ...response.data.data.massMessages])
               }
            })
            .catch((err) => {
               console.log(err)
            })
   }

   const handleHistoryMailingScroll = (e) => {
      if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight) {
         nextHistoryMailingCursor && loadHistoryMailing()
      }
   }

   useEffect(() => {
      axios({
         method: 'get',
         url: `/api/v1/mass-send/get?limit=20`,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               console.log(response.data.data.error)
            } else {
               setNextHistoryMailingCursor(response.data.data.next_cursor)
               setHistoryMailing(response.data.data.massMessages)
            }
         })
         .catch((err) => {
            console.log(err)
         })
   }, [])

   return (
      <div className="mailing-wrapper" onScroll={handleHistoryMailingScroll}>
         <div className="mailing-header">
            <h2 className='mailing-title'>
               Рассылки
            </h2>
            <MailingTypeSelect
               onSelect={handleSelectMailingType}
            />
         </div>
         <AddMailing
            isVisible={isShowAddMailingModal}
            mailingType={mailingType}
            toggleShowAddMailingModal={toggleShowAddMailingModal}
            tag={tag}
            allowedTag={allowedTag}
            status={status}
            allowedStatus={allowedStatus}
            channelList={channelList.filter(
               (channel) => channel.status === 'active'
            )}
            allowedChannel={allowedChannel}
            setHistoryMailing={setHistoryMailing}
            shouldClearMailingModalCache={shouldClearMailingModalCache}
            setShouldClearMailingModalCache={setShouldClearMailingModalCache}
            handleOpenIncorrectNumbersModal={handleOpenIncorrectNumbersModal}
            currentUser={currentUser}
         />
         <IncorrectNumbersModal
            show={showIncorrectNumbersModal}
            numbersList={incorrectNumbers}
            onChooseNewFile={handleChangeFileForIncorrectNumbersModal}
            onSubmit={handleSubmitIncorrectNumbersModal}
            onClose={handleCloseIncorrectNumbersModal}
         />
         {historyMailing.length !== 0 ? (
            <ul className="mailing-history-list">
               <li className="mailing-history-header">
                  <div className="mailing-history-header_block">
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">
                           Имя менеджера
                        </span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">Каналы</span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">Статусы</span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">Теги</span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">Текст</span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">Вложения</span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">
                           Дата и время
                        </span>
                     </div>
                  </div>
               </li>
               {historyMailing.map((item) => {
                  return (
                     <MailingHistoryItem
                        key={item.id}
                        item={item}
                        tag={tag}
                        status={status}
                        channelList={channelList}
                     />
                  )
               })}
            </ul>
         ) : (
            <span className="tags-status-settings_subtitle">
               Вы пока не отправили ни одной рассылки
            </span>
         )}
      </div>
   )
}

export default Mailing
