import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import '../auth.css'
import './ForgotPasswordPage.css'

import { Button } from '../../components/Button'
import AuthLogo from '../../components/AuthLogo'

const ForgotPasswordPage = ({ onSubmit, onReturnBack }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const handleReturnBack = () => onReturnBack()

   const formik = useFormik({
      initialValues: {
         email: ''
      },
      validationSchema: yup.object().shape({
         email: yup.string()
            .matches(
               /^[^\s@]+@[^\s@.]+\.[^\s@.]+$/,
               'Пожалуйста, введите корректный email-адрес'
            )
           .required('Email-адрес обязателен для заполнения'),
      }),
      onSubmit: async (values, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            await onSubmit(values)
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   return (
      <div className="auth">
         <AuthLogo />
         <div className="login-card forgot-password-page__card">
            <div className="login-card-wrapper forgot-password-page__wrapper">
               <div className="forgot-password-page__header">
                  <div className="forgot-password-page__header-title">
                     <button
                        type="button"
                        className="forgot-password-page__return-btn"
                        onClick={handleReturnBack}
                     >
                        <img
                           src="/assets/controls/return-back-icon.svg"
                           alt=""
                        />
                     </button>
                     <h4 className="forgot-password-page__header-text">
                        Восстановление пароля
                     </h4>
                  </div>
                  <p className="forgot-password-page__description">
                     Для восстановления пароля, введите Email, на который был
                     зарегистрирован аккаунт менеджера
                  </p>
               </div>
               <form
                  className="forgot-password-form"
                  onSubmit={formik.handleSubmit}
               >
                  {formik.status && (
                     <p className="forgot-password-form__status">
                        {formik.status}
                     </p>
                  )}
                  <div className="forgot-password-form-control">
                     <label
                        htmlFor="email"
                        className={`forgot-password-form-control__label ${
                           formik.touched.email && formik.errors.email
                              ? 'forgot-password-form-control__label_error'
                              : ''
                        }`}
                     >
                        {(formik.touched.email && formik.errors.email) ||
                           'Email'}
                     </label>
                     <input
                        type="text"
                        className="login-card-credentials-field forgot-password-form-input"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="example@mail.com"
                        autoComplete="off"
                     />
                  </div>
                  <Button
                     type="submit"
                     text="Восстановить пароль"
                     customStyle="forgot-password-form__submit-btn"
                     disabled={disableSubmitBtn}
                  />
               </form>
            </div>
         </div>
      </div>
   )
}

export default ForgotPasswordPage