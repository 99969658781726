import './tagsStatus.css'
import { useCallback, useEffect, useState } from 'react'
import TagItem from './TagItem'
import StatusItem from './StatusItem'
import TagModal from '../modals/TagModal'
import StatusModal from '../modals/StatusModal'
import PaginationItem from './PaginationItem'
import ReturnBtn from '../mainbar/ReturnBtn'
import { Button } from '../Button'

const TagsStatus = ({
   isMainbarTablet,
   tag,
   addTag,
   deleteTag,
   editTag,
   status,
   addStatus,
   deleteStatus,
   editStatus,
   handleReturnBtnClick
}) => {
   const [showAddTagModal, setShowAddTagModal] = useState(false)
   const toggleAddTagModal = () => {
      setShowAddTagModal(!showAddTagModal)
   }

   const [currentTagPage, setCurrentTagPage] = useState(1)
   const [tagsPerPage, setTagsPerPage] = useState(15)
   useEffect(() => {
      isMainbarTablet ? setTagsPerPage(5) : setTagsPerPage(15)
   }, [isMainbarTablet])
   const lastTagIndex = currentTagPage * tagsPerPage
   const firstTagIndex = lastTagIndex - tagsPerPage
   const currentTag = tag.slice(firstTagIndex, lastTagIndex)

   const pageNumbers = []

   for (let i = 1; i <= Math.ceil(tag.length / tagsPerPage); i++) {
      pageNumbers.push(i)
   }

   const handleAddTag = useCallback(
      (body) => {
         addTag(body)
         if (currentTag.length >= tagsPerPage) {
            setCurrentTagPage(pageNumbers.length)
         }
      },
      [addTag, currentTag]
   )

   const handleDeleteTag = useCallback(
      (id) => {
         deleteTag(id)
         if (currentTag.length <= 1 && currentTagPage > 1) {
            setCurrentTagPage((prevState) => prevState - 1)
         }
      },
      [deleteTag, currentTag, currentTagPage]
   )

   const [showAddStatusModal, setShowAddStatusModal] = useState(false)
   const toggleAddStatusModal = () => {
      setShowAddStatusModal(!showAddStatusModal)
   }

   const [currentStatusPage, setCurrentStatusPage] = useState(1)
   const [statusesPerPage, setStatusesPerPage] = useState(15)
   useEffect(() => {
      isMainbarTablet ? setStatusesPerPage(5) : setStatusesPerPage(15)
   })
   const lastStatusesIndex = currentStatusPage * statusesPerPage
   const firstStatusesIndex = lastStatusesIndex - statusesPerPage
   const currentStatuses = status.slice(firstStatusesIndex, lastStatusesIndex)

   const statusPageNumbers = []

   for (let i = 1; i <= Math.ceil(status.length / statusesPerPage); i++) {
      statusPageNumbers.push(i)
   }

   const handleAddStatus = useCallback(
      (body) => {
         addStatus(body)
         if (currentStatuses.length >= statusesPerPage) {
            setCurrentStatusPage(statusPageNumbers.length)
         }
      },
      [addStatus, currentStatuses]
   )

   const handleDeleteStatus = useCallback(
      (id) => {
         deleteStatus(id)
         if (currentStatuses.length <= 1 && currentStatusPage > 1) {
            setCurrentStatusPage((prevState) => prevState - 1)
         }
      },
      [deleteStatus, currentStatuses, currentStatusPage]
   )

   return (
      <div className="tags-status-settings">
         <TagModal
            isVisible={showAddTagModal}
            title={'Добавить тег'}
            text={'Добавить тег'}
            element={''}
            accept={handleAddTag}
            cancel={toggleAddTagModal}
         />
         <StatusModal
            isVisible={showAddStatusModal}
            title={'Добавить статус'}
            text={'Добавить статус'}
            element={''}
            accept={handleAddStatus}
            cancel={toggleAddStatusModal}
         />
         <div className="tags-status-settings_wrapper">
            <ReturnBtn
               isMainbarTablet={isMainbarTablet}
               onClick={handleReturnBtnClick}
            />
            <div className="tags-status-settings_item">
               <div
                  className={
                     tag.length === 0
                        ? 'tags-status-settings_block'
                        : 'tags-status-settings_block-active'
                  }
               >
                  <h2 className="tags-status-settings_title">Теги</h2>
                  <Button
                     text='+ Добавить тег'
                     customStyle="tags-status-settings__add-btn"
                     onClick={() => setShowAddTagModal(true)}
                     disabled={tag.length >= 20}
                  />
               </div>
               {tag.length === 0 ? (
                  <span className="tags-status-settings_subtitle">
                     У вас еще нет ни одного тега
                  </span>
               ) : (
                  <></>
               )}
               <ul
                  style={tag.length ? {} : { display: 'none' }}
                  className="tags-status-settings_list"
               >
                  {currentTag.map((t) => {
                     return (
                        <TagItem
                           key={t.id}
                           t={t}
                           addTag={addTag}
                           deleteTag={handleDeleteTag}
                           editTag={editTag}
                        />
                     )
                  })}
               </ul>
               {(tag.length > 15 || (tag.length > 5 && isMainbarTablet)) && (
                  <>
                     <PaginationItem
                        setCurrentPage={setCurrentTagPage}
                        pageNumbers={pageNumbers}
                        currentPage={currentTagPage}
                     />
                  </>
               )}
            </div>
            <div className="tags-status-settings_item">
               <div
                  className={
                     status.length <= 1
                        ? 'tags-status-settings_block'
                        : 'tags-status-settings_block-active'
                  }
               >
                  <h2 className="tags-status-settings_title">Статусы</h2>
                  <Button
                     text='+ Добавить статус'
                     customStyle="tags-status-settings__add-btn"
                     onClick={() => setShowAddStatusModal(true)}
                     disabled={status.length >= 15}
                  />
               </div>
               {status.length <= 1 ? (
                  <span className="tags-status-settings_subtitle">
                     У вас ещё ни одного статуса
                  </span>
               ) : (
                  <></>
               )}
               {status.length > 1 && (
                  <>
                     <ul className="tags-status-settings_list">
                        {currentStatuses.map((st) => {
                           return (
                              <StatusItem
                                 key={st.id}
                                 st={st}
                                 deleteStatus={handleDeleteStatus}
                                 editStatus={editStatus}
                              />
                           )
                        })}
                     </ul>
                     {(status.length > 15 ||
                        (status.length > 5 && isMainbarTablet)) && (
                        <PaginationItem
                           currentPage={currentStatusPage}
                           setCurrentPage={setCurrentStatusPage}
                           pageNumbers={statusPageNumbers}
                        />
                     )}
                  </>
               )}
            </div>
         </div>
      </div>
   )
}

export default TagsStatus
