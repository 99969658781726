import React, { useState } from 'react'
import './TemplateList.css'
import { useClickOutside } from '../../hooks'

const TemplateListItem = ({ templateSubsection, templates, onTemplateSelect }) => {
   const { subsection_id, name } = templateSubsection
   const currentTemplates = templates.filter(template => template.subsection_id === subsection_id)

   const [showSublist, setShowSublist] = useState(false)

   const handleTemplateSubsectionClick = () => setShowSublist(show => !show)

   const handleTemplateSelect = (template) => {
      onTemplateSelect(template)
   }

   const templateListItemRef = useClickOutside(() => setShowSublist(false))

   return (
      <li ref={templateListItemRef} className={`template-list-item ${showSublist ? "template-list-item_active" : ''}`}>
         <button 
            className={`template-list-item__subsection`}
            onClick={handleTemplateSubsectionClick}
         >
            {name}
            <img className='template-list-item__subsection-icon' src='/assets/sidebar/select-dropdown-icon.svg' alt='' />
         </button>
         <ul className={`template-list-item__sublist ${showSublist ? "template-list-item__sublist_show" : ''}`}>
            {currentTemplates.length > 0
               ? currentTemplates.map(template => (
                  <li 
                     key={template.id}
                     className="template-list-item__sublist-item"
                     onClick={() => handleTemplateSelect(template)}
                  >
                     {template.name}
                  </li>
               ))
               : (
                  <p className="template-list-item__sublist-message">
                     У вас нет шаблонов, созданных в этой группе
                  </p>
               )
            }
         </ul>
      </li>
   )
}

export default TemplateListItem