import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   contactsLoading: false,
   contacts: [],
   selectedContactId: null,
   contactDataIsLoading: false,
   selectedContact: null
}

const contactsSlice = createSlice({
   name: 'contacts',
   initialState,
   reducers: {
      fetchContactsStarted: (state) => {
         state.contactsLoading = true
      },
      fetchContactsSuccess: (state, action) => {
         state.contacts = [...state.contacts, ...action.payload] 
      },
      fetchContactsFinished: (state) => {
         state.contactsLoading = false
      },
      setContacts: (state, action) => {
         state.contacts = action.payload
      },
      setSelectedContactId: (state, action) => {
         state.selectedContactId = action.payload
      },
      fetchContactDataStarted: (state) => {
         state.contactDataIsLoading = true
      },
      fetchContactDataSuccess: (state, action) => {
         state.selectedContact = action.payload 
      },
      fetchContactDataFinished: (state) => {
         state.contactDataIsLoading = false
      },
   }
})

export const {
   fetchContactsStarted,
   fetchContactsSuccess,
   fetchContactsFinished,
   setContacts,
   setSelectedContact,
   setSelectedContactId,
   fetchContactDataStarted,
   fetchContactDataSuccess,
   fetchContactDataFinished
} = contactsSlice.actions
export default contactsSlice.reducer